import * as io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

declare var appConfig;

@Injectable()
export class SocketService {
  // private url = appConfig.url;
  private url = appConfig.socketUrl;
  private socket;
  constructor() {
    this.socket = io(this.url);
  }
  connectSocket(QrCodeID: string): void {
    this.socket = io(this.url, { query: `RequestUniqueID=${QrCodeID}` });
  }
  createroom(QrCodeID: string) {
    this.socket.emit('create-room', QrCodeID, function (response) {
      console.log(response);
    });
  }

  getDeviceList = (callback) => {
    //  return Observable.create((observer) => {
    this.socket.on('list-scanned-devices', (data) => {
      callback(data);
      //observer.next(data);
    });
    // });
  }


  getstarttestResponse = () => {
    return Observable.create((observer) => {
      this.socket.on('start-test-reponse', (data) => {
        observer.next(data);
      });
    });
  }
  getendttestResponse = () => {
    return Observable.create((observer) => {
      this.socket.on('end-test-reponse', (data) => {
        observer.next(data);
      });
    });
  }
  testvaluechange = (callback) => {
    this.socket.on('test-value-change', (data) => {
      callback(data);
    })
  }
  fingerprintvaluechange = (callback) => {
    this.socket.on('fingerprint-value-change', (data) => {
      callback(data);
    })
  }
  toggleShow = (callback) => {
    this.socket.on('on-display-toggle', (data) => {
      callback(data);
    })
  }
  screenColorChange = () => {
    return Observable.create((observer) => {
      this.socket.on('on-toggle-change', (data) => {
        observer.next(data);
      });
    });
  }
  screenchange = (callback) => {

    // return Observable.create((observer) => {
    this.socket.on('on-screen-change', (data) => {
      // observer.next(data);
      callback(data);
    });
    // });
  }
  secretCodePopUp() {
    return Observable.create((observer) => {
      this.socket.on('secret_code_popup-event', (data) => {
        observer.next(data);
      });
    });
  }


  OnOrderMailSent = () => {
    return Observable.create((observer) => {
      this.socket.on('alert-order-mail-sent', (data) => {
        observer.next(data);
      });
    });
  }

  OnTermsCondtionChecked = () => {
    return Observable.create((observer) => {
      this.socket.on('on-check-terms-conditon', (data) => {
        observer.next(data);
      });
    });
  }

  OnDisplayColorChange = () => {
    return Observable.create((observer) => {
      this.socket.on('on-screen-color-change', (data) => {
        observer.next(data);
      });
    });
  }
  OnFaceDetection = () => {
    return Observable.create((observer) => {
      this.socket.on('on-face-detection', (data) => {
        observer.next(data);
      });
    });
  }
  OnCaptureDevice = () => {
    return Observable.create((observer) => {
      this.socket.on('on-capture-device', (data) => {
        observer.next(data);
      });
    });
  }
  OnTouchScreen = () => {
    return Observable.create((observer) => {
      this.socket.on('on-touch-screen', (data) => {
        observer.next(data);
      });
    });
  }
  OntoggleChange = () => {
    return Observable.create((observer) => {
      this.socket.on('on-toggle-change', (data) => {
        observer.next(data);
      });
    });
  }

  deviceScanComplete = () => {
    return Observable.create((observer) => {
      this.socket.on('device-scan-complete', (data) => {
        observer.next(data);
      });
    });
  }


  maximumlimit(data) {
    this.socket.emit('maximum-limit', JSON.stringify(data), function (response) {
      console.log(response);
    });
  }
  deviceremoved(data) {
    this.socket.emit('device-removed', JSON.stringify(data), function (response) {
      console.log(response);
    });
  }
  PageScrollEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('on-screen-scroll-event', (data) => {
        observer.next(data);
      });
    });
  }
  getPaymentSummary = () => {
    return Observable.create((observer) => {
      this.socket.on('on-payment-summary', (data) => {
        observer.next(data);
      });
    });
  }
  getPaymentFieldsData = () => {
    return Observable.create((observer) => {
      this.socket.on('on-payment_form_field', (data) => {
        observer.next(data);
      });
    });
  }
  getDeviceReport = () => {
    debugger
    return Observable.create((observer) => {
      this.socket.on('show-device-report', (data) => {
        observer.next(data);
      });
    });
  }
  getCustomerInformationFields = () => {
    return Observable.create((observer) => {
      this.socket.on('show-custumer-form-field', (data) => {
        observer.next(data);
      });
    });
  }

  devicedisconnectresponse = () => {
    return Observable.create((observer) => {
      this.socket.on('device-disconnect-response', (data) => {
        observer.next(data);
      });
    });
  }
  onRemoveDeviceEvent = () => {
    return Observable.create((observer) => {
      this.socket.on('on-remove-device-event', (data) => {
        observer.next(data);
      });
    });
  }
  disconnectSocket = () => {
    return Observable.create((observer) => {
      this.socket.on('disconnect', (data) => {
        observer.next(data);
      });
    });
  }

  userDisconnected(data) {
    this.socket.emit('user-disconnected', JSON.stringify(data), function (response) {
      console.log(response);
    });
  }

  connect_error = () => {
    return Observable.create((observer) => {
      this.socket.on('connect_error', (data) => {
        observer.next(data);
      });
    });
  }

  Onreconnect = () => {
    return Observable.create((observer) => {
      this.socket.on('reconnect', (data) => {
        observer.next(data);
      });
    });
  }
  Onreconnecting = () => {
    return Observable.create((observer) => {
      this.socket.on('reconnecting', (data) => {
        observer.next(data);
      });
    });
  }
  tryConnect(data) {
    this.socket.emit('try-reconnecting', data, function (response) {
      console.log(response);
    });
  }
  declarationChange = () => {
    return Observable.create((observer) => {
      this.socket.on('declaration-change-event', (data) => {
        observer.next(data);
      });
    });
  }


  onProvisioningComplete = () => {
    return Observable.create((observer) => {
      this.socket.on('web-on-provisioning-complete', (data) => {
        observer.next(data);
      });
    });
  }

  // Socket service for e-sign
  ESignComplete(Data: any) {
    return Observable.create((observer) => {
      this.socket.emit('e-sign-complete', Data, function (response) {
        console.log(response);
        observer.next(response)
      });
    })
  }
  ESignComplete_back = () => {
    debugger
    return Observable.create((observer) => {
      this.socket.on('e-sign-complete_back', (data) => {
        debugger
        observer.next(data);
      });
    });
  }
  customerToolTipInfo = () => {
    return Observable.create((observer) => {
      this.socket.on('on-custumer_form_tooltip', (data) => {
        observer.next(data);
      });
    });
  }
  uploadImageSuccess=(callback)=>{
    this.socket.on('upload-image-success',(data)=>{
      callback(data);
    })
  }
}
