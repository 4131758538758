// import { Pipe, PipeTransform } from '@angular/core';
// import { TranslateService } from './translate.service';

// @Pipe({
//     name: 'translate',
//     pure: false
// })
// export class TranslatePipe implements PipeTransform {

//     constructor(private translate: TranslateService) { }
//     transform(key: any): any {
//         try {
//             const keyPair = key.split('.');
//             const pageName = keyPair[0];
//             const text = keyPair[1];
//             //  let data = this.translate.data[key] || key;
//             return this.translate.data[pageName][text] || key;
//         } catch (error) {
//             return key;
//         }

//     }

// }
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
    name: 'translate',
    pure: false
})
export class TranslatePipe implements PipeTransform {

    constructor(private translate: TranslateService) { }
    transform(key: any): any {
        try {
            const keyPair = key.split('.');
            const pageName = keyPair[0];
            const text = keyPair[1];
            //  let data = this.translate.data[key] || key;
            return this.translate.data[key] || key;
        } catch (error) {
            return key;
        }
        

    }

}
