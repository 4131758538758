import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()

export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selected: BehaviorSubject<string> = new BehaviorSubject<string>('dashboard');

    display(value: boolean) {
        this.status.next(value);
    }
    activeTab(value: string) {
      this.selected.next(value);
  }
}
