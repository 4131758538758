import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { Testfilter } from '../../pipe/TestFilter';
import { DashboardChildComponent } from '../../shared/child-dashboard/dashboardchild.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//import { QRCodeModule } from 'angular2-qrcode';



export function HttpLoaderFactory(httpClient: HttpClient) {
    // return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [TranslatePipe, Testfilter, DashboardChildComponent],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
            
            CommonModule,   FormsModule
            
    ],
    exports: [
        TranslateModule,
        TranslatePipe,
        Testfilter,
        DashboardChildComponent
    ],
    providers: []
})
export class SharedModule { }
