import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner'
import { ResponseModel } from '../Model/Response.Model';
import { TranslateService } from '../manual/i18n/translate.service';
import { FormControl } from '@angular/forms';
import { isNull, isNullOrUndefined } from 'util';
declare var appConfig;


@Injectable()
export class CommonService {
  ipAddress: any;
  ip: string;
  private actionUrl: string;
  public trackIPUrl: string;
  public getTranslationurl: string;
  public getlanurl: string;

  // tslint:disable-next-line: deprecation
  constructor(private http: Http, private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient, private spinner: NgxSpinnerService, private translate: TranslateService) {
    this.actionUrl = appConfig.url;
    this.trackIPUrl = appConfig.GetIPUrl;
    this.getlanurl = appConfig.GetLanguagesUrl;
    this.getTranslationurl = appConfig.translationAPIURL;

  }

  getTranslation(strbody: any, isLoader = false) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body = strbody;
    // this.spinner.show();

    this.httpClient.post<ResponseModel>(this.getTranslationurl, body, { headers: headers }).subscribe(response => {
      if (response.IsSuccess) {

        var translationObject = {
          data: "123",
          data2: "123"
        };
        response.Data.ConfigurationKeys.forEach(element => {
          if (element.Key.includes('Receive_a_return_kit_to_pack')) {

            translationObject[element.Key] = element.Translation.replace('[X]', localStorage.ReturnKitReceiveDays);
          } else {
            translationObject[element.Key] = element.Translation;
          }

        });

        // localStorage.setItem('translationdata', JSON.stringify(translationObject));
        console.log(response.Data);
      } else {
        console.log(response.Message);
      }
      // if (!isLoader)
      // this.spinner.hide();
      this.translate.use(translationObject);
    })
  }

  getMethod(url: string): Promise<any> {
    var userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'),
      'EnterPrisePartnerID': userInfo != null ? userInfo.EnterprisePartnerID : null,
      'CacheManagerID': userInfo != null ? userInfo.CacheManagerID : null,
    });
    const options = new RequestOptions({
      headers: headers
    });
    return this.http.get(this.actionUrl + url, options)
      .toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }

  // getDataWithParameter(strbody: string, url: string): Promise<any> {
  //   return this.http.get(this.actionUrl + url + '/' + strbody)
  //     .toPromise()
  //     .then(response => this.extractArray(response))
  //     .catch(this.handleErrorPromise);
  // }

  postmethod(strbody: any, url: string) {

    var userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'),
      'EnterPrisePartnerID': userInfo != null ? userInfo.EnterprisePartnerID : null,
      'CacheManagerID': userInfo != null ? userInfo.CacheManagerID : null,
      'SubscriberProductCode': userInfo != null ?userInfo.EnterprisePartner : null,
      'SubscriberProductID': userInfo != null ?userInfo.EnterprisePartnerID : null,
      "languageid":"1"
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }
  getIpAddress(callback) {
    this.http.get(this.trackIPUrl).toPromise().then(response => {
      const resObject = JSON.parse(response['_body']);
      this.ipAddress = resObject.ip;
      callback('', this.ipAddress);
    }
    ).catch(error => {
      callback(error);
    });
  }

  makeFileRequest(url: string, files: Array<File>, orderData) {

    let complteUrl = this.actionUrl + url
    return Observable.fromPromise(new Promise((resolve, reject) => {

      let formData: any = new FormData();
      let xhr = new XMLHttpRequest();
      for (let file of files) {

        formData.append('InspectionImages', file, file.name);
      }
      formData.append('TempOrderID', orderData.TempOrderID);
      formData.append('OrderDetailID', orderData.OrderDetailID);
      formData.append('EnterPrisePartnerID', orderData.EnterPrisePartnerID);
      formData.append('StoreID', orderData.StoreID);
      formData.append('UploadedFilesName', orderData.UploadedFilesName);
      formData.append('IsContractImage', orderData.IsContractImage);
      xhr.onreadystatechange = function () {
        
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", complteUrl, true);
      xhr.send(formData);
    }));
  }


  public BoxDetailsHtml(): Promise<any> {
    return this.http.get(`assets/Template/CAUK/BoxDetails.html?v=${new Date().getTime()}`)
      .toPromise()
      .then(response => response.text()
      )
      .catch(this.handleErrorPromise);
  }

  public BagCloseOrders(): Promise<any> {
    return this.http.get(`assets/Template/CAUK/CloseBagOrders.html?v=${new Date().getTime()}`)
      .toPromise()
      .then(response => response.text()
      )
      .catch(this.handleErrorPromise);
  }






  protected extractArray(res: Response, showprogress: boolean = true) {
    const data = res.json();
    return data || [];
  }
  private handleErrorPromise(error: Response | any) {

    return Promise.reject(error.message || error);
  }


  Add(strbody: any, url: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'DeviceType': 'Web',
      'DeviceName': 'Web',
      Authorization: sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'),
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }
  Add_Esign(strbody: any, url: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'DeviceType': 'Web',
      'DeviceName': 'Web'
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }

  PostWithToken(strbody: any, url: string, _DeviceName: string) {

    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'),
      SubscriberProductID: appConfig.SubscriberProductID,
      DeviceType: 'Web-Manual-Order',
      DeviceName: _DeviceName,
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }

  getDataWithHeaderParameter(strbody: string, url: string, _DeviceName: string): Promise<any> {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: sessionStorage.getItem('token_type') + ' ' + sessionStorage.getItem('access_token'),
      SubscriberProductID: appConfig.SubscriberProductID,
      DeviceType: 'Web-Manual-Order',
      DeviceName: _DeviceName,
    });
    const options = new RequestOptions({
      headers: headers
    });
    return this.http.get(this.actionUrl + url + '/' + strbody, options)
      .toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }



  //manual
  // PostLogin(strbody: any, url: string) {

  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     //'SubscriberProductID': config.SubscriberProductID,
  //     //'SubscriberProductCode': config.SubscriberProductCode,
  //     //'SubscriberID': config.SubscriberID,
  //     'IPAddress': this.ipAddress,
  //   });
  //   const options = new RequestOptions({ headers: headers });
  //   const body = strbody;

  //   return this.http.post(this.actionUrl + url, body,
  //     options).toPromise()
  //     .then(response => this.extractArray(response))
  //     .catch(this.handleErrorPromise);
  // }
  PostLogin(strbody: any, url: string) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'IPAddress': this.ipAddress,
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }
  IsValidEmail(email) {

    if (email.length <= 2) {
      return false;
    }
    if (email.indexOf("@") == -1) {
      return false;
    }

    var parts = email.split("@");
    var dot = parts[1].indexOf(".");
    var len = parts[1].length;
    var dotSplits = parts[1].split(".");
    var dotCount = dotSplits.length - 1;

    if (dot == -1 || dot < 2 || dotCount > 2) {
      return false;
    }

    for (var i = 0; i < dotSplits.length; i++) {
      if (dotSplits[i].length == 0) {
        return false;
      }
    }
    if (dotSplits[1].length < 2 || dotSplits[1].length > 3) {
      return false;
    }

    return true;
  }

  toHHMMSS(secs) {
    var sec_num = parseInt(secs, 10);
    sec_num = sec_num / 1000;
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  }

  public luhncheck(value): boolean {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) return false;

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    value = value.replace(/\D/g, "");

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) == 0;
  }
  PostPrintContract(strbody: any, url: string, userInfo) {

    const headers = new Headers({
      'Content-Type': 'application/json',
      'DeviceType': 'Web',
      'DeviceName': 'Web',
      'subscriberproductcode': userInfo.EnterprisePartner,
      'subscriberproductid': userInfo.EnterprisePartnerID,
    });
    const options = new RequestOptions({
      headers: headers
    });
    const body = strbody;
    return this.http.post(this.actionUrl + url, body,
      options).toPromise()
      .then(response => this.extractArray(response))
      .catch(this.handleErrorPromise);
  }
  // noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { 'whitespace': true };
  // }
  noWhitespaceValidator(control: FormControl) {
    
    if(!isNullOrUndefined(control.value))

    {
    let isValidData = (control && control.value && control.value.toString()).length > 0 ? true : false;
    const isWhitespace = isValidData ? (control && control.value && control.value.toString() || '').trim().length === 0: false;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  }

  MakeAntivirusApiCall(url: string, file: File, orderData) {
    
    let complteUrl = this.actionUrl + url
    return Observable.fromPromise(new Promise((resolve, reject) => {
      
      let formData: any = new FormData();
      let xhr = new XMLHttpRequest();

      
      if (file != null) {
        formData.append('InspectionImages', file, file.name);
      }


      formData.append('EnterPrisePartnerID', orderData.EnterPrisePartnerID);
      formData.append('LanguageID', orderData.LanguageID);
      formData.append('UploadedFilesName', orderData.UploadedFilesName);
      xhr.onreadystatechange = function () {
        
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.open("POST", complteUrl, true);
      xhr.send(formData);
    }));
  }
  ValidateNIP(control: FormControl)
  {
    debugger
   

   
    let number = control.value;
    if(number=='0000000000')
    {
      return false?  null : { 'whitespace': true };
    }
    else
    {
       let multiplyValue = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    if (number) {
      let res = 0;
      for (let i = 0; i < 9; i++) {
        res = res + (+number[i] * +multiplyValue[i]);
        
      }
      let finalValue = (res % 11);
      if (number[9] == finalValue) {
        return true?  null : { 'whitespace': true };
      } else {
        return false?  null : { 'whitespace': true };
      }
    }
    }
  

  }

  VerifyPESEL(control: FormControl) {
    debugger
    let number = control.value;
    let multiplyValue = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    if (number) {
      let res = 0;
      let response=0;
      let result=0;
      for (let i = 0; i < 10; i++) {
        result= (number[i] * +multiplyValue[i]);
        let sum=result.toString();
        if(sum.length>1)
        {
          sum=sum.slice(-1);
          res=res+parseInt(sum);
        }
        else{
        res=res+result;       
        }
       
      }
      var ress = res.toString();
      if(ress.length>1)
      {
        
       let  dd= ress.slice(-1);
         res=parseInt(dd);
      
      }
      
      let finalValue = 10 - res;
      if (number[10] == finalValue) {
        return true?  null : { 'whitespace': true };
      } else {
        return false?  null : { 'whitespace': true };
      }
    }
  }
  ValidateIDNO(control: FormControl)
  {
    debugger
    
    
    var SelectedPassportType = JSON.parse(sessionStorage.getItem('SelectedPassportType'));
    let data = control.value;
    if(data)
    {
      let first= data.slice(0,3)
      let last= data.slice(3,9)
     const  validEmailRegEx =/^[a-zA-Z]+$/
     const    NumberOnly=  /^[0-9]+$/
      if(!SelectedPassportType){
       if (validEmailRegEx.test(first) && NumberOnly.test(last))
       {
         
        return true ? null : { 'whitespace': true };
      
       }
       else{
  
        return false ? null : { 'whitespace': true };
       }
     }
     if(SelectedPassportType){
 
      //  if(validEmailRegEx.test(data))
      //  {
      //   return true ? null : { 'whitespace': true };
     
      //  }
      //  else{
 
      //   return false ? null : { 'whitespace': true };
     
      //    } 
     }
 
   
   }
 
 }

 ValidateCustomerForm(IsCommercial:boolean,data:any){
     debugger
     if(IsCommercial && (data.FirstName!="null" && data.LastName!="null" && data.Street!="null" && (data.postCode!="null" || data.PostCode!="null")
     && data.CompanyName!="null" && data.NIP!="null" && data.BuildingNo!="null" && data.ApartmentNo!="null" && data.PESEL!="null"
     && data.IdentityType!="null" && data.IDNo!="null" && data.CustomerAccountNumber!="null") &&
     (data.FirstName!=undefined && data.LastName!=undefined && data.Street!=undefined && (data.postCode!=undefined || data.PostCode!=undefined)
     && data.CompanyName!=undefined && data.NIP!=undefined && data.BuildingNo!=undefined && data.ApartmentNo!=undefined && data.PESEL!=undefined
     && data.IdentityType!=undefined && data.IDNo!=undefined && data.CustomerAccountNumber != undefined)){
       return true;
     }
     else if(!IsCommercial && (data.FirstName!="null" && data.LastName!="null" && data.Street!="null" && (data.postCode!="null" || data.PostCode!="null")
      && data.BuildingNo!="null" && data.ApartmentNo!="null" && data.PESEL!="null"
     && data.IdentityType!="null" && data.IDNo!="null" && data.CustomerAccountNumber!="null") &&
     (data.FirstName!=undefined && data.LastName!=undefined && data.Street!=undefined && (data.postCode!=undefined || data.PostCode!=undefined)
      && data.BuildingNo!=undefined && data.ApartmentNo!=undefined && data.PESEL!=undefined
     && data.IdentityType!=undefined && data.IDNo!=undefined && data.CustomerAccountNumber != undefined)){
       return true;
     }
     else{
       return false;
     }
   }
  

}
