import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/service/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';
declare var appConfig
@Component({
  selector: 'app-trade-in-confirmed-model',
  templateUrl: './trade-in-confirmed-model.component.html',
  styleUrls: ['./trade-in-confirmed-model.component.css']
})
export class TradeInConfirmedModelComponent implements OnInit {
  @Output() emitService = new EventEmitter();
  public translatePipe: any;
  TradeInConfirmedData: any
  public userInfo: any;
  public TestGrouping: any
  public TestGroupingList: any
  public IscheckBoxchecked: boolean;
  //public IscheckBoxchecked: boolean;
  public checkBoxError: boolean;
  public showData: any
  public isAllTestPass = 1;
  public isAllStoreTestsPass = 1;
  public isAllSellerTestsPassMobile = 1;
  public isAllStoreTestsPassMobile = 1;
  IsValidIMEI: boolean;

  mySubscription: any;
  @Output() GetTempRetailOrder = new EventEmitter<boolean>();
  Modelshow: boolean = false;
  orderResult: any;
  OrderID: any;
  OrderInfoShow: boolean;

  public IsFactoryReset = false;
  public IsSimRemoved = false;
  IsPriceChanged: any;
  public IsSellerAcountRemoved = false;
  constructor(public activeModal: NgbActiveModal, private router: Router,
    private _service: CommonService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private translate: TranslateService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.translatePipe = new TranslatePipe(translate);
    console.log("constructure", JSON.parse(sessionStorage.getItem('BeforeStoreInspectionTestResult')))
    this.showData = JSON.parse(sessionStorage.getItem('BeforeStoreInspectionTestResult'))
  }

  ngOnInit() {
    debugger
    console.log(this.IsPriceChanged);
    this.userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    this.TradeInConfirmedData.BeforeStoreInspectionTestResult = this.showData
    this.isAllTestPass = (this.TradeInConfirmedData.BeforeStoreInspectionTestResult != null) ? this.TradeInConfirmedData.BeforeStoreInspectionTestResult.filter(x => x.IsParentPass == false && x.TestType == "Inspection Test").length : 1;
    this.isAllStoreTestsPass = (this.TradeInConfirmedData.AfterStoreInspectionTestResult != null) ? this.TradeInConfirmedData.AfterStoreInspectionTestResult.filter(x => x.IsParentPass == false && x.TestType == "Inspection Test").length : 1;

    let AllMobileTestsSeller = (this.TradeInConfirmedData.BeforeStoreInspectionTestResult != null) ? this.TradeInConfirmedData.BeforeStoreInspectionTestResult.filter(x => x.DiagnosticType == 'Manual' || x.DiagnosticType == 'Automated') : null;
    if (AllMobileTestsSeller != null && AllMobileTestsSeller != undefined && AllMobileTestsSeller.length > 0) {
      this.isAllSellerTestsPassMobile = AllMobileTestsSeller.map(x => { return { count: this.filtertest(x.GroupTestsList).length } }).filter(x => x.count > 0).length;
    }
    else {
      this.isAllSellerTestsPassMobile = 1;
    }


    let AllMobileTests = (this.TradeInConfirmedData.AfterStoreInspectionTestResult != null) ? this.TradeInConfirmedData.AfterStoreInspectionTestResult.filter(x => x.DiagnosticType == 'Manual' || x.DiagnosticType == 'Automated') : null;
    if (AllMobileTests != null && AllMobileTests != undefined && AllMobileTests.length > 0) {
      this.isAllStoreTestsPassMobile = AllMobileTests.map(x => { return { count: this.filtertest(x.GroupTestsList).length } }).filter(x => x.count > 0).length;
    }
    else {
      this.isAllStoreTestsPassMobile = 1;
    }
  }
  ngOnChanges() {
    debugger
    this.TradeInConfirmedData.AfterStoreInspectionTestResult = this.TradeInConfirmedData.AfterStoreInspectionTestResult
    this.isAllTestPass = (this.TradeInConfirmedData.BeforeStoreInspectionTestResult != null) ? this.TradeInConfirmedData.BeforeStoreInspectionTestResult.filter(x => x.IsParentPass == false && x.TestType == "Inspection Test").length : 1;
    this.isAllStoreTestsPass = (this.TradeInConfirmedData.AfterStoreInspectionTestResult != null) ? this.TradeInConfirmedData.AfterStoreInspectionTestResult.filter(x => x.IsParentPass == false && x.TestType == "Inspection Test").length : 1;

    let AllMobileTestsSeller = (this.TradeInConfirmedData.BeforeStoreInspectionTestResult != null) ? this.TradeInConfirmedData.BeforeStoreInspectionTestResult.filter(x => x.DiagnosticType == 'Manual' || x.DiagnosticType == 'Automated') : null;
    if (AllMobileTestsSeller != null && AllMobileTestsSeller != undefined && AllMobileTestsSeller.length > 0) {
      this.isAllSellerTestsPassMobile = AllMobileTestsSeller.map(x => { return { count: this.filtertest(x.GroupTestsList).length } }).filter(x => x.count > 0).length;
    }
    else {
      this.isAllSellerTestsPassMobile = 1;
    }

    let AllMobileTests = (this.TradeInConfirmedData.AfterStoreInspectionTestResult != null) ? this.TradeInConfirmedData.AfterStoreInspectionTestResult.filter(x => x.DiagnosticType == 'Manual' || x.DiagnosticType == 'Automated') : null;
    if (AllMobileTests != null && AllMobileTests != undefined && AllMobileTests.length > 0) {
      this.isAllStoreTestsPassMobile = AllMobileTests.map(x => { return { count: this.filtertest(x.GroupTestsList).length } }).filter(x => x.count > 0).length;
    }
    else {
      this.isAllStoreTestsPassMobile = 1;
    }
  }
  // async acceptOrder(object) {
  //  debugger
  //   if(this.IscheckBoxchecked !== true){
  //     this.checkBoxError = true;
  //     return;
  //   }
  //   this.spinner.show(); 

  //   object.IsConfirmOrderMailPause=appConfig.IsConfirmOrderMailPause
  //   this._service.Add(object, 'api/generate-temp-order').then(result => {
  //     if (result.IsSuccess === true) {
  //       this.Modelshow=true;
  //        this.orderResult=result.Data;
  //        this.OrderID= result.Data.OrderDetailID;
  //       const newLocal = this;
  //               newLocal.activeModal.close('Close click');
  //       this.activeModal.dismiss();   
  //       if(document.getElementById('closeChildInspectionModal')){
  //         document.getElementById('closeChildInspectionModal') .click()
  //       }
  //         this.router.navigate(['/store-dashboard'])
  //       this.spinner.hide();
  //       sessionStorage.removeItem('BeforeStoreInspectionTestResult')
  //     } else {
  //       object.SerialNumber='N/A'
  //       object.IMEI='N/A'
  //       this.toster.warning(result.Message)
  //       this.spinner.hide();
  //     }
  //   })
  // }
  acceptOrder(object) {
    this.emitService.emit(true);//to return event in parent component
  }
  filtertest(testlist) {
    let ids = appConfig.ParentTestIDNotShownInAutomateTest;
    const items = testlist.filter(x => x.TestStatus !== -2 && (x.TestStatus == 0 || x.TestStatus == -1) && !ids.includes(x.TestID));
    return items;
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }


  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  closeLostModel() {
    //  this.document.body.classList.remove('modal-open');
    this.OrderInfoShow = false;
    this.router.navigate(['/store-dashboard'])

  }
  fnFilterCosmaticTest(item) {
    var data = item.filter(x => x.TestType == 'Cosmetic Test');
    return data;
  }
}
