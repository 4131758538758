import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/service/common.service';
import { HttpClient } from '@angular/common/http';
import { SocketService } from 'src/app/service/socket/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';

declare var appConfig
@Component({
  selector: 'app-my-qrcode-patch-modal',
  templateUrl: './my-qrcode-patch-modal.component.html',
  styleUrls: ['./my-qrcode-patch-modal.component.css']
})
export class MyQrcodePatchModalComponent implements OnInit {
  public ManualProcessData: any;
  public translatePipe: any;
  public IsManualDisable = true;
  public SubmitButtonText: any;
  public CloseButtonText: any;
  public IsDataFectchComplete = false;
  public IsManualMessage = '';
  public ShowFMIPButton = true;
  public ShowLBSButton = true;
  public popisManualChecked = false;
  public IsManualButtonSubmit = false;
  public IsSerialNumberValid = true
  public IsValidImei = false
  public PreviousData: any
  public deviceList: any
  public imeiserial: any
  public IsSerialNumbercheck: any;
  public AddBodyClass = true;

  @Output() UpdateIMEISerailStatus = new EventEmitter<boolean>();
  IMEI: string;
  IsSerialNumberAlreadyExists = false;
  @Input() DuplicateIMEIErrorMessage;



  constructor(public activeModal: NgbActiveModal,
    private _service: CommonService,
    private http: HttpClient,
    private socketService: SocketService,
    private spinner: NgxSpinnerService, private router: Router,
    private toster: ToastrService,
    private translate: TranslateService
  ) {
    this.translatePipe = new TranslatePipe(translate);
  }

  ngOnInit() {
    debugger

    sessionStorage.setItem('PreviousDataPopUp', JSON.stringify(this.ManualProcessData))

    this.deviceList = JSON.parse(sessionStorage.getItem('customerDetails'));
    this.SubmitButtonText = this.translatePipe.transform('Submit');
    this.CloseButtonText = this.translatePipe.transform('Close');
    if (this.ManualProcessData.IMEI == null || this.ManualProcessData.IMEI == "N/A" || this.ManualProcessData.IMEI == " ") {

      this.ManualProcessData.IsFMIP = false,
        this.ManualProcessData.IsLostStolen = false
    }


    if (this.ManualProcessData.IMEI != null && this.ManualProcessData.IMEI != "N/A" && this.ManualProcessData.IMEI != '') {

      this.IsImeiValid(this.ManualProcessData);
    }
    if ((this.ManualProcessData.IMEI == null || this.ManualProcessData.IMEI == "N/A" || this.ManualProcessData.IMEI == " " ||
      this.ManualProcessData.IMEI == '') && (this.ManualProcessData.SerialNumber != '' || this.ManualProcessData.SerialNumber != "N/A")) {
      this.IsSerialValid(this.ManualProcessData.SerialNumber);
    }

    if (this.ManualProcessData.DeviceCategoryID > 6) {
      this.IsValidImei = true;
      this.IsManualMessage = ''
      this.ManualProcessData.FMIP = false;
      this.ManualProcessData.LostStolen = false;
      this.IsDataFectchComplete = true;
    }
    // sessionStorage.getItem("customerDetails",JSON.parse(this.deviceList));

  }

  ngOnChanges() {
    // this.PreviousData=this.ManualProcessData
    // console.log('this.ManualProcessData',this.ManualProcessData)
  }
  ngAfterViewInit() {

  }
  onNativeChange(e) { // here e is a native event
    // debugger;
    this.IsManualDisable = true;
    if (e.target.checked) {
      this.IsManualDisable = false;
      //this.IsDataFectchComplete = true;
      this.ManualProcessData.IMEI = '';
      this.ManualProcessData.SerialNumber = '';

    }
  }
  async fetchDetails(item) {

    debugger
    this.spinner.show();
    var reBody = {
      SubscriberProductCode: appConfig.SubscriberProductCode,
      //UDIRefcode: 'FD3ABFB9A212469',
      UDIRefcode: item.QrCodeID,  // set as UDI 'FD3ABFB9A212469', //
      RequestProductURL: '',
    };
    await this._service.PostWithToken(reBody, 'api/get-scanned-devices-data-by-udi', '').then(result => {

      if (result && result.IsSuccess && result.Data && result.Data.DeviceInformation && result.Data.DeviceInformation.length > 0) {
        var _imei = result.Data.DeviceInformation.find(x => x.DiagnosticID === 1);
        var _serialno = result.Data.DeviceInformation.find(x => x.DiagnosticID === 3);
        if (_imei !== undefined && _imei != null && _imei.DiagnosticValue) {
          item.IMEI = _imei.DiagnosticValue;
          this.IsDataFectchComplete = true;
          this.IsValidImei = true;
          this.performLBSCheck(item);
          //this.IsManualDisable = false;
          this.SubmitButtonText = this.translatePipe.transform('Finish');
        } else if (!_serialno) {
          item.IMEI = 'N/A';
          this.toster.warning(this.translatePipe.transform('No_records_found'))
        }
        if (_serialno !== undefined && _serialno != null) {
          item.SerialNumber = _serialno.DiagnosticValue == null ? 'N/A' : _serialno.DiagnosticValue;
          this.IsSerialNumbercheck = true
          this.performLBSCheck(item);

        } else {
          item.SerialNumber = 'N/A';
        }

      } else {
        this.toster.warning(this.translatePipe.transform('No_records_found'))
      }
      this.spinner.hide();
    });
  }

  checkfmip(item) {

    this.ShowFMIPButton = false;
    this.spinner.show();
    if (item.IMEI === '' || item.IMEI === undefined || item.IMEI === null) {
      return undefined;
    } else {
      const body = {};
      this.http.post('https://fmipchecker-api.cellde.com/api/LostStolen/' + item.IMEI, body).subscribe(data => {
        ;
        console.log('FMIP Check : ' + item.IMEI);
        console.log('FMIP Check Response: ' + JSON.stringify(data));
        item.FMIP = true;
        //item.LostStolen = false
        this.spinner.hide();
      }, error => {
        console.log(error.json());
      });
    }
  }
  async performLBSCheck(item) {
    debugger
    item.LostStolen = false;
    this.ShowLBSButton = false;
    this.spinner.show();
    if (this.ManualProcessData.Serialcheck == true || this.IsSerialNumbercheck == true) {
      this.imeiserial = item.SerialNumber;
      //item.IMEI =item.SerialNumber;
    }
    else {
      this.imeiserial = item.IMEI;
    }
    if (this.imeiserial === '' || this.imeiserial === undefined || this.imeiserial === null) {
      return undefined;
    } else {


      await this._service.getMethod('api/LostStolen/' + this.imeiserial).then(data => {
        ;
        debugger

        var Result = JSON.parse(data.Data.ResponseBody);

        if (Result.result == 'failed') {
          if (Result.fmip == 'notactivated') {
            this.ManualProcessData.FMIP = false;
            this.ManualProcessData.LostStolen = true;
            this.ManualProcessData.IsFMIP = false;
            this.ManualProcessData.IsLostStolen = true;

          }
          else if (Result.fmip == 'activated' && this.ManualProcessData.Make == 'Apple') {
            this.ManualProcessData.FMIP = true;
            this.ManualProcessData.LostStolen = false;
            this.ManualProcessData.IsFMIP = true;
            this.ManualProcessData.IsLostStolen = false;
          }
          else if (Result.fmip == 'notapplicable') {
            this.ManualProcessData.FMIP = false;
            this.ManualProcessData.LostStolen = true;
            this.ManualProcessData.IsFMIP = false;
            this.ManualProcessData.IsLostStolen = true;
          }
        }
        else if ((Result.fmip == 'notactivated' && Result.result == 'passed') || (Result.fmip == 'notapplicable' && Result.result == 'passed')) {
          this.ManualProcessData.FMIP = false;
          this.ManualProcessData.LostStolen = false;
          this.ManualProcessData.IsFMIP = false;
          this.ManualProcessData.IsLostStolen = false;
        }
        else {
          // this.IsManualMessage = this.translatePipe.transform("Please_enter_valid_IMEI");
        }
        this.Duplicatevalidate(item);
        console.log('FMIP Check : ' + item.IMEI);
        console.log('FMIP Check Response: ' + JSON.stringify(data));

        // item.FMIP = true;
        //item.LostStolen = true
        // this.spinner.hide();
      }, error => {
        console.log(error.json());
      });
    }
  }
  onManualSubmit(item) {

    item.FMIP = undefined;
    item.LostStolen = undefined;
    item.isManualIMEI = this.popisManualChecked;
    this.IsManualButtonSubmit = true;
    this.IsManualMessage = '';
    item.SerialNumber = (item.SerialNumber=='N/A' ? '' : item.SerialNumber);
    if (this.IsManualButtonSubmit && item.IMEI !== '' && item.IMEI !== null && (this.ManualProcessData.DeviceCategoryID == 1)) {
      var isnum = /^\d+$/.test(item.IMEI);
      if (item.IMEI.length >= 15 && item.IMEI.length <= 15 && isnum) {
        if (!this._service.luhncheck(item.IMEI)) {
          this.IsManualMessage = this.translatePipe.transform("Please_enter_valid_IMEI");
          return;
        } else {
          item.isManualIMEI = true;
          const response = this.checkfmip(item);
          if (response) {
          }
        }
      } else {
        this.IsManualMessage = this.translatePipe.transform("Please_enter_valid_IMEI");
      }
    } else if (this.ManualProcessData.DeviceCategoryID == 1) {
      this.IsManualMessage = this.translatePipe.transform("Please_enter_IMEI");
    }

    if (this.ManualProcessData.DeviceCategoryID > 1 && this.ManualProcessData.DeviceCategoryID < 7 && (item.SerialNumber == 'N/A' || item.SerialNumber == "")) {
      this.IsSerialNumberValid = false;
    }
    else if (item.SerialNumber !== 'N/A' && item.SerialNumber != "") {
      if (!/^[a-zA-Z0-9]+$/.test(item.SerialNumber)) {
        this.IsSerialNumberValid = false
      } else {
        this.IsDataFectchComplete = true;
        this.IsSerialNumberValid = true
      }
    }
  }
  OnProvisingProfileClose() {

    if (!this.IsManualButtonSubmit) {
      this.ManualProcessData.SerialNumber = 'N/A'
      this.ManualProcessData.IMEI = 'N/A'
    }
    this.activeModal.dismiss('Cross click')
    this.RestorePreviousValue();
    // document.body.classList.add('popup-open');


  }

  IsImeiValid(item) {
    debugger;
    if (this.ManualProcessData.DeviceCategoryID == 1) {
      if (item.IMEI !== '' && item.IMEI !== null) {
        this.DuplicateIMEIErrorMessage = '';
        var isnum = /^\d+$/.test(item.IMEI);
        if (item.IMEI.length > 14 && item.IMEI.length <= 15 && isnum) {
          debugger;
          if (this._service.luhncheck(item.IMEI)) {
            this.IsDataFectchComplete = true;
            this.IsValidImei = true;
            this.IsManualMessage = ''
            item.LostStolen = false;
            item.FMIP = false;

            this.performLBSCheck(this.ManualProcessData);

          } else {
            this.IsDataFectchComplete = false;
            this.IsValidImei = false;
            this.IsManualMessage = this.translatePipe.transform('Please_enter_valid_IMEI');
          }

        } else {
          this.IsDataFectchComplete = false;
          this.IsValidImei = false;
          this.IsManualMessage = this.translatePipe.transform('Please_enter_valid_IMEI');
        }
      }
    }
    else if (this.ManualProcessData.DeviceCategoryID > 6) {
      this.IsValidImei = true;
      this.IsManualMessage = ''
      item.FMIP = false;
      item.LostStolen = false;
      this.IsDataFectchComplete = true;
    }
    else {
      this.IsValidImei = true;
      this.IsManualMessage = ''
      item.FMIP = false;
    }
  }

  async SaveImeiSerialNumberManualy(data) {

    debugger
    data.SerialNumber = (data.SerialNumber=='N/A' ? '' : data.SerialNumber);
    await this.Duplicatevalidate(data);
    if (!this.IsValidImei && this.ManualProcessData.DeviceCategoryID == 1 && this.ManualProcessData.IsCallFunction) {
      console.log('Invalid IMEI');
      return
    }
    if (!this.IsSerialNumberValid && this.ManualProcessData.DeviceCategoryID >= 1 && this.ManualProcessData.DeviceCategoryID < 7) {
      console.log('Invalid Serial');
      return
    }


    this.ManualProcessData.FMIP = true ? data.FMIP : false;
    this.ManualProcessData.LostStolen = true ? data.LostStolen : false;


    let reBody = {
      EnterprisePartnerID: data.EnterprisePartnerID,
      StoreID: data.StoreID,
      IsManualIMEI: this.popisManualChecked,
      TempID: data.ID,
      IMEI: data.IMEI,
      SerialNumber: data.SerialNumber,
      IsFMIP: data.IsFMIP,
      IsLostStolen: data.IsLostStolen


    }
    this._service.Add(reBody, 'api/save-imei-manually').then(result => {

      if (result && result.IsSuccess) {
        //this.UpdateIMEISerailStatus.emit(true);
        this.ManualProcessData.IsManualIMEI = this.popisManualChecked
        this.ManualProcessData.acceptOrderDisable = false;
        this.toster.success(this.translatePipe.transform("Data_submitted_successfully_Please_complete_the_order"), "")
      } else {
        this.toster.error('An Error Occured', 'Error');
        data.IMEI = 'N/A';
        data.SerialNumber = 'N/A';
      }
      this.activeModal.dismiss('Cross click')
      this.spinner.hide();
    });

  }

  IsSerialValid(SerialNumber) {
    console.log(SerialNumber)
    if (this.ManualProcessData.DeviceCategoryID > 1 && this.ManualProcessData.DeviceCategoryID < 7 && (SerialNumber == 'N/A' || SerialNumber == "")) {
      this.IsSerialNumberValid = false;
      this.IsManualMessage = ''
    }
    else if (SerialNumber !== 'N/A' && SerialNumber != "") {
      this.DuplicateIMEIErrorMessage = '';
      if (this.ManualProcessData.DeviceCategoryID == 1) {
        if (Number(SerialNumber.length) <= 15 && Number(SerialNumber.length) >= 6 && /^[a-zA-Z0-9]+$/.test(SerialNumber)) {
          this.IsSerialNumberValid = true;
          this.IsManualMessage = ''
          this.IsDataFectchComplete = true;
          if (this.ManualProcessData.IMEI == '' || this.ManualProcessData.IMEI == 'N/A') {
            // this.ManualProcessData.IMEI= this.ManualProcessData.SerialNumber;
            this.ManualProcessData.Serialcheck = true
            this.performLBSCheck(this.ManualProcessData);
          }
          //this.ManualProcessData.acceptOrderDisable = false;
          //this.IsDataFectchComplete = true;
        } else {
          this.IsSerialNumberValid = false;
          this.IsManualMessage = ''
        }
      }
      else if (this.ManualProcessData.DeviceCategoryID > 1 && this.ManualProcessData.DeviceCategoryID < 7) {
        if (Number(SerialNumber.length) <= 50 && Number(SerialNumber.length) >= 6 && /^[a-zA-Z0-9]+$/.test(SerialNumber)) {
          this.IsSerialNumberValid = true;
          this.IsManualMessage = ''
          this.IsDataFectchComplete = true;
          //this.ManualProcessData.acceptOrderDisable = false;
        } else {
          this.IsSerialNumberValid = false;
          this.IsManualMessage = ''
        }
      }
    }
  }

  onImgError(DeviceVendor) {
    if (DeviceVendor === 'Apple') {
      this.ManualProcessData.DeviceModelImage = '/assets/images/apple';
    }
    else {
      this.ManualProcessData.DeviceModelImage = '/assets/images/android';
    }
  }
  RestorePreviousValue() {
    const data = JSON.parse(sessionStorage.getItem('PreviousDataPopUp'));
    if (this.IsManualMessage !== '' && !this.IsValidImei || !this.IsManualButtonSubmit) {
      this.ManualProcessData.IMEI = data.IMEI;
    }
    if (!this.IsSerialNumberValid || !this.IsManualButtonSubmit) {
      this.ManualProcessData.SerialNumber = data.SerialNumber;
    }
    this.activeModal.dismiss('Cross click');
    if (this.AddBodyClass)
      document.body.classList.add('popup-open');

  }



  _AllowNumberOnly(event: any) {
    this.DuplicateIMEIErrorMessage = '';
    const pattern = /[0-9()\b\0\-]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  _AllowNumberAndCharOnly(event: any) {
    this.DuplicateIMEIErrorMessage = '';
    const pattern = /[a-zA-Z0-9\b\0\-]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  async Duplicatevalidate(items) {
    debugger
    this.spinner.show();
    var reBody = {
      IMEI: items.IMEI,
      EnterprisePartnerId: items.EnterprisePartnerID,
      SerialNumber: items.SerialNumber
    };
    //this._service.postmethod(this.EnterpriseModelObj, 'api/PhoneModel/DuplicateValidate').then(data => {
    await this._service.postmethod(reBody, 'api/duplicate-validate-imei-serial').then(data => {
      this.spinner.hide();
      if (data != null) {
        debugger
        if (!data.Data.IsValid) {
          this.IsValidImei = false;
          this.IsSerialNumberAlreadyExists = true;
          this.IsSerialNumberValid = false;
          var errorMessage = this.translatePipe.transform('Order_with_same_IMEI');
          this.DuplicateIMEIErrorMessage = errorMessage.replace("{{day}}", data.Data.ErrorMessage);
          // this.toster.warning(errorMessage.replace("{{day}}", data.Data.ErrorMessage));
          return;
        }
        else {
          this.IsValidImei = true;
          this.IsSerialNumberAlreadyExists = false;
          this.IsSerialNumberValid = true;
          // this._imeiNumber = this.IMEISearchModelName.toString();
          // sessionStorage.setItem('_imeiNumber', this.IMEISearchModelName.toString());
          // this._errorMessage = "";
          // this.ModelFound = false;
          // this.IsValidIMEI = true;
          // this.spinner.hide();
          // if (type == "IMEI") {
          //   this.isIMEI = true;
          //   this.IsSerialNumber = false;
          // }
          // else {
          //   this.isIMEI = false;
          //   this.IsSerialNumber = true;
          // }
          // this.fnSaveCustomerDetail();
        }
      }
    }, error => {
      this.spinner.hide();
      this.router.navigate(['/error']);
    });
  }
}
