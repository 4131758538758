import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'Testfilter'
})
export class Testfilter implements PipeTransform {
    transform(items: any[], value: string): any[] {
        
        if (!items) { return []; }
        if (!value) { return items; }
        // tslint:disable-next-line:no-unused-expression
        if (value === '' || value == null) { return items; }
        value = value;
        return items.filter(e =>
            e.LanguageID == value
        );
    }
}