import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-voucher-modal',
  templateUrl: './voucher-modal.component.html',
  styleUrls: ['./voucher-modal.component.css']
})
export class VoucherModalComponent implements OnInit {
  OrderData: any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
