import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class NavigateService implements CanDeactivate<CanComponentDeactivate> {

   
  canDeactivate(component: CanComponentDeactivate) {
      var check =JSON.parse(sessionStorage.getItem('isDeviceConnected'));
      if(check)
      {
      if(confirm("Changes you made may not be saved."))
      {
       return true;
      }
      else
      {
        return false;
      }
    }
    else{
      return true;
    }
    // return component.canDeactivate ? component.canDeactivate() : true;
  }

}