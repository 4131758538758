import { CanActivate,CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AlwaysAuthGuard implements CanActivate {
  constructor(private Router: Router) { }
  canActivate() {
    const StoreInfo = sessionStorage.getItem('StoreInfo');
    if (StoreInfo === null || StoreInfo === undefined || StoreInfo === '') {
      this.Router.navigateByUrl('/');
    } else {
      return true;
    }
    return true;
  }
}
