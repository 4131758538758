import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { SocketService } from '../../service/socket/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from 'src/app/Model/Response.Model';

declare var appConfig;
@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html',
  styleUrls: ['./app.layout.component.css']
})

export class AppLayoutComponent {
  public getTranslationurl: string;
  enableLanguage = appConfig.enableLanguage;
  title = 'HeaderComponent';
  pageurl = '';
  routePath = '';
  messageModal = 'none';
  messageObject: any;
  StoreInfo: any;
  language: any;
  languages: any;

  public Listlanguage: any;
  public languagesession: any;

  public translatePipe: any;
  NotificationCount: number;
  myOptions = {
    'placement': 'left',
    'show-delay': 500
  }
  StoreCodes: any;
  constructor(private routes: Router,
    private _service: CommonService,
    private socketService: SocketService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    this.getTranslationurl = appConfig.translationAPIURL;
    this.StoreInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    this.StoreCodes = JSON.parse(sessionStorage.getItem('Storecode'));

    this.translatePipe = new TranslatePipe(translate);
    const language = sessionStorage.getItem('language');
    //var language = sessionStorage.getItem("language");


    if (language) {
      this.language = language;
    } else {
      this.language = appConfig.LanguageCode;
    }
  }
  ngOnInit() {
    debugger
    this.StoreCodes = JSON.parse(sessionStorage.getItem('Storecode'));

    this.GetLanguage();
    // this.setLanguage(language);
  }


  logout() {
    this.spinner.show();
    var reqdata = {
      "QrCodeID": this.StoreInfo.StoreID,
    }
    this.socketService.userDisconnected(reqdata);

    if (this.StoreInfo.StoreID !== '' && this.StoreInfo.StoreID !== undefined) {
      const parameters = {
        QrCodeID: this.StoreInfo.StoreID
      }
      this.toster.clear();
      this._service.PostLogin(parameters, 'api/login/logout-all').then(result => {
        if (result.IsSuccess === true && result.Data === true) {
          console.log('logged-out');
          let LanguageID = sessionStorage.getItem('LanguageID');
          let LanguageData = sessionStorage.getItem('LanguageData');
          sessionStorage.clear();
          sessionStorage.setItem('language', this.language);
          sessionStorage.setItem('LanguageID', LanguageID);
          sessionStorage.setItem('LanguageData', LanguageData);
          this.spinner.hide();
          this.routes.navigateByUrl('/login');
        }
      },
        error => {
          if (error.status === 401) {
          }
          console.log(error);
          throw error;
        });
    }
    this.CloseModal();

  }

  CloseModal() {
    this.messageModal = 'none';
  }

  getBOHNotificationDetails() {
    let parameters = {
      "StoreID": this.StoreInfo.StoreID,
      "BohCloseBoxDays": appConfig.BohBackCloseDays
    }
    this._service.postmethod(parameters, 'api/get-boh-notification-details').then(result => {

      if (result.IsSuccess) {

        this.NotificationCount = result.Data.OpenConfirmedOrder;
        this.toster.clear();
        if (Number(this.NotificationCount) > 0) {
          // var _message = 'There are atleast ' + result.Data.OpenConfirmedOrder + " devices ready for the boxing, Don't forget to send them."
          var _message = this.translatePipe.transform('There_are_atleast') + ' ' + result.Data.OpenConfirmedOrder + ' ' + this.translatePipe.transform('devices_ready_for_the_boxing');
          //There are atleast 34 devices ready for the boxing, Don't forget to send them
          //There are atleast {10} devices are ready for Boxing Do not forget to send them

          this.toster.error(_message, null,
            {
              timeOut: 0,
              extendedTimeOut: 0,
              tapToDismiss: true,
              positionClass: 'toast-top-center',
              closeButton: true
            }
          ).onTap
            .subscribe(() => this.toasterClickedHandler());

        }

      }
    });

  }

  toasterClickedHandler() {
    //console.log('Toastr clicked');
    //this.routes.navigateByUrl('/store-back-office');
    this.redirecttoRoute('/store-back-office');
  }

  redirecttoRoute(_route) {
    const pagename = this.routes.url;
    //     alert(pagename);
    // alert(_route);
    if (pagename === '/magic-mirror-screen' && _route === pagename) {
      // do nothing
    } else if (pagename === '/magic-mirror-screen' && _route !== pagename) {
      //open page in new tab
      this.routes.navigate([]).then(result => { window.open(_route, '_blank').focus(); });
    } else {
      //open page in same tab
      this.routes.navigateByUrl(_route);
    }
  }


  setLanguage(language) {
    this.language = language;
    var UserData = {
      SubscriberProductCode: appConfig.SubscriberProductCode,
      LanguageCode: language,
      KeyFor: appConfig.KeyFor
    }
    this.getTranslation(UserData, true);
    var element = document.getElementById("CosmeticGrade");
    if (element != null)
      element.classList.remove("active");


    this.languages = sessionStorage.getItem('language');
    //  sessionStorage.setItem('Languageinfo', JSON.stringify(UserData.LanguageCode));


    //console.log(language);
    //sessionStorage.setItem("language", this.language);
    sessionStorage.setItem('language', language);
    var data = this.Listlanguage.filter(a => a.LanguageCode == language);
    sessionStorage.setItem("LanguageID", JSON.stringify(data[0].LanguageID));
    // this.getBOHNotificationDetails();

    this.translate.use(this.language);
  }
  GetLanguage() {
    this.Listlanguage = JSON.parse(sessionStorage.getItem("LanguageData"));
    if (!sessionStorage.getItem("LanguageData")) {
      sessionStorage.setItem('language', appConfig.LanguageCode);
      this.language = sessionStorage.getItem('language');
      // sessionStorage.setItem('LanguageID', '1');
      sessionStorage.setItem("translationdata", null);

      var UserData = {
        SubscriberProductCode: appConfig.SubscriberProductCode,
        LanguageCode: this.language,
        KeyFor: appConfig.KeyFor
      }

      this.getTranslation(UserData);
    } else {
      this.language = sessionStorage.getItem('language');
      var UserData = {
        SubscriberProductCode: appConfig.SubscriberProductCode,
        LanguageCode: this.language,
        KeyFor: appConfig.KeyFor
      }
      this.getTranslation(UserData);
      // this.getBOHNotificationDetails();
    }
    // else {
    //   this.language = this.language;
    //   if(this.language=='en')
    //   {
    //     if(document.body.classList.contains('Dutch'))
    //     {
    //       document.body.classList.remove('Dutch');
    //     }
    //     document.body.classList.add('English');
    //   }
    //   else
    //   {
    //     if(document.body.classList.contains('English'))
    //     {
    //       document.body.classList.remove('English');
    //     }
    //     document.body.classList.add('Dutch');
    //   }
    // }
  }

  getTranslation(strbody: any, isLoader = false) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = strbody;
    if (isLoader)
      this.spinner.show();

    this.httpClient.post<ResponseModel>(this.getTranslationurl, body, { headers: headers }).subscribe(response => {

      if (response.IsSuccess) {
        var translationObject = {
          data: "123",
          data2: "123"
        };
        response.Data.ConfigurationKeys.forEach(element => {
          if (element.Key.includes('Receive_a_return_kit_to_pack')) {

            translationObject[element.Key] = element.Translation.replace('[X]', localStorage.ReturnKitReceiveDays);
          } else {
            translationObject[element.Key] = element.Translation;
          }
        });

        // localStorage.setItem('translationdata', JSON.stringify(translationObject));
        console.log(response.Data);
      } else {
        console.log(response.Message);
      }
      this.getBOHNotificationDetails();
      if (isLoader)
        this.spinner.hide();
      this.translate.use(translationObject);
    })
  }

}
