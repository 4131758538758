import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import{TranslateService} from  '../../manual/i18n/translate.service';
import{TranslatePipe} from  '../../manual/i18n/translate.pipe';
import { DomSanitizer } from '@angular/platform-browser';
declare var appConfig;
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})

export class ImageViewerComponent implements OnInit {
  
  @Input() Images;
  private _albums = [];
  items: GalleryItem[];
  SFTPURL = '';
  itemss: [];
  Photos: any[] = [];
  //items:any[]=[];
  publicpdf = "http://quickrepair-wh-web.product.veridic.local/OnlineCustomerDocuments/4QDE4RNAC8.pdf";
  public translatePipe: TranslatePipe;


  constructor(public gallery: Gallery,
    public activeModal: NgbActiveModal, public sanitizer: DomSanitizer, private translate:TranslateService) {
      this.translatePipe = new TranslatePipe(translate);
   

  }

  ngOnInit() {
    
    // this.activeModal.dismiss();

    // 1. Create gallery items
    this.SFTPURL = appConfig.SFTPURL;
    // var gallery=  JSON.parse(sessionStorage.getItem("Galleries"));

    // for(var i=0;i<gallery.length;i++)
    // {
    //   if(gallery[i] != "" && gallery[i] !=null && gallery[i]!=undefined)
    //   {
    //     this.Photos[i]=this.SFTPURL + gallery[i]
    //   }
    // }
    // entry = prompt("Enter your name")
    // entryArray = entry.split("");

    this.items = this.Images.Data.map(item => {
      if (item.DocumentData != "" && !item.DocumentData.includes('.pdf')) {
        return new ImageItem({ src: this.SFTPURL + item.DocumentData, thumb: this.SFTPURL + item.DocumentData })
      }
      else if(item.DocumentData.includes('.pdf')){
        let temp = {data : null};
        temp.data  =  {src : this.SFTPURL + item.DocumentData} 
       return temp;
      }

    });
    console.log(this.items);
    this.items.splice(this.items.length - 1, 1);
    this.items.splice(0, 1)
    console.log(this.items);


    // Load items into the lightbox
    this.basicLightboxExample();

    // Load item into different lightbox instance
    // With custom gallery config
    this.withCustomGalleryConfig();

    // for (let i = 1; i <= 4; i++) {
    //   this.Images.forEach(element => {
    //     const src = element.DocumentData;
    //     //const caption = 'Image ' + element + ' caption here';
    //     const thumb = element.DocumentData;
    //     const album = {
    //        src: src,
    //        //caption: caption,
    //        thumb: thumb
    //     };

    //   this._albums.push(album);
    //   });
    //   this._albums.push()
    // }

    // for (let i = 1; i <= 4; i++) {
    //   const src = 'demo/img/image' + i + '.jpg';
    //   const caption = 'Image ' + i + ' caption here';
    //   const thumb = 'demo/img/image' + i + '-thumb.jpg';
    //   const album = {
    //      src: src,
    //      caption: caption,
    //      thumb: thumb
    //   };

    // this._albums.push(album);
    // }
  }

  CloseActiveModal() {
    

    this.activeModal.dismiss();
    //  var body = document.body;
    //  body.classList.add("modal-open");
    document.body.classList.add('popup-open');
    //this.document.body.classList.add('modal-open');
  }


  basicLightboxExample() {
    

    this.gallery.ref().load(this.items);
  }
  /**
   * Use custom gallery config with the lightbox
   */
  withCustomGalleryConfig() {

    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
}
