import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { LoaderService } from '../app/service/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateService as langService } from '../app/manual/i18n/translate.service';
import { CommonService } from '../app/service/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseModel } from './Model/Response.Model';
declare var appConfig;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  // language = "en";
  title = 'CellDe-Online';
  ApiURL = appConfig.APIURL;
  public showLoader;
  getlanurl: any;

  constructor(private commonService: CommonService, private _loaderService: LoaderService, private cdRef: ChangeDetectorRef,
    private translate: TranslateService, private _translatelang: langService, private httpClient: HttpClient) {
    var languageCode = appConfig.LanguageCode;
    
    this.getlanurl = appConfig.GetLanguagesUrl;
    if (sessionStorage.getItem('language') != null && sessionStorage.getItem('language') != "") {
      languageCode = sessionStorage.getItem('language');
    }
    else {
      languageCode = appConfig.LanguageCode;
    }
    if (!sessionStorage.getItem("LanguageData")) {
      var UserData = {
        SubscriberProductCode: appConfig.SubscriberProductCode,
        LanguageCode: languageCode,
        KeyFor: appConfig.KeyFor
      }
      this.commonService.getTranslation(UserData);
      this.translate.use(appConfig.ML_Language);
    }
    else {
      var UserData = {
        SubscriberProductCode: appConfig.SubscriberProductCode,
        LanguageCode: languageCode,
        KeyFor: appConfig.KeyFor
      }
      this.commonService.getTranslation(UserData);
      this.translate.use(appConfig.ML_Language);
    }
  }

  ngOnInit() {
  }


  onLanguageChange(lang: any) {
    this._translatelang.use(lang);
  }

  ngAfterViewChecked() {

    this.cdRef.detectChanges();
  }
}
