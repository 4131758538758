import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 
@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    data: any = {};
    constructor(private http: HttpClient) { }
 
    use(translation: any): Promise<{}> {
        return new Promise<{}>((resolve, reject) => {
            const language = sessionStorage.getItem('language');
            let langPath;
        //   var translation = JSON.parse(localStorage.getItem('translationdata'));
            this.data = Object.assign({}, translation || {});
            debugger;
            this.data[0]=this.data[0];
            resolve(this.data);
            // const langPath = `assets/i18n/${lang || 'en'}.json`;
            // this.http.get<{}>(langPath).subscribe(
            //     translation => {
            //         translation = JSON.parse(localStorage.getItem('translationdata'));
            //         this.data = Object.assign({}, translation || {});
            //         resolve(this.data);
            //     },
            //     () => {
            //         this.data = {};
            //         resolve(this.data);
            //     }
            // );
        });
    }
}