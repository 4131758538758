import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlwaysAuthGuard } from './common/authguard/authguard';
import { AppLayoutComponent } from './shared/applayout/app.layout.component';
import { NavigateService } from './common/NavigationService';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'delete-account', loadChildren: './delete-account/delete-account.module#DeleteAccountModule' },
  { path: 'p', loadChildren: './expired/expired.module#ExpiredModule' },
  { path: 'print-wrapper', loadChildren: './print-wrapper/print-wrapper.module#PrintWrapperModule' },
  { path: 'print-wrapper/:UniqueID', loadChildren: './print-wrapper/print-wrapper.module#PrintWrapperModule' },
  { path: 'print-contract/:UniqueID', loadChildren: './print-contract/print-contract.module#PrintContractModule' },

  { path: 'faq', loadChildren: './faq/faq.module#FaqModule' },
  { path: 'terms-condition', loadChildren: './terms-condition/TermsCondition.module#TermsConditionModule' },
 // { path: 'download', loadChildren: './download/download.module#DownloadModule' },
 { path: 'order-print', loadChildren: './Wrapper/wrapperfoh.module#WrapperfohModule'},
 { path: 'print-order-wrapper/:UniqueID', loadChildren: './print-order-wrapper/order-wrapper.module#PrintOrderModule' },
  {
    path: '', component: AppLayoutComponent, canActivate: [AlwaysAuthGuard],
    children: [
      {
        path: 'magic-mirror-screen', canDeactivate: [NavigateService],
        loadChildren: './device-diagnostic/device-diagnostic.module#DeviceDiagnosticModule',
      },
      { path: 'pending-orders', loadChildren: './store-inspection-v2/storeInspectionV2.module#StoreInspectionModule' },
      { path: 'store-back-office', loadChildren: './boh/boh.module#BOHModule' },
      { path: 'manualtrade-in', loadChildren: './manual/FOH/foh.module#FOHModule'},
      { path: 'manualInspection', loadChildren: './manual/inspection/inspection.module#InspectionModule'},
      {path: 'store-dashboard', loadChildren: './store-dashboard/store-dashboard.module#StoreDashboardModule' },
      { path: 'generate-asset-ids', loadChildren: './qrcode-generator/qrcode-generator.module#QrCodeGeneratorModule' },
      { path: 'edit-order/:TempOrderID', loadChildren: './edit-order/edit-order.module#EditOrderModule' },
    ]
  },
  { path: 'print-file', loadChildren: './uploaded-file/uploaded-file.module#UploadedFileModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
