// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';

// custom modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutComponent } from './shared/applayout/app.layout.component';
import { CommonService } from './service/common.service';
import { LoaderService } from './service/loader.service';
import { SocketService } from './service/socket/socket.service';

import { AlwaysAuthGuard } from './common/authguard/authguard';
import { ToastrModule } from 'ngx-toastr';
import { NavigateService } from '../app/common/NavigationService';
import { TranslateService } from '../app/manual/i18n/translate.service';
import {CookieService} from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angular2-qrcode'; 
import {WebcamModule} from 'ngx-webcam';


import { TradeInConfirmedModelComponent } from './shared/trade-in-confirmed-model/trade-in-confirmed-model.component';
import { MyQrcodePatchModalComponent } from './model-popup/my-qrcode-patch-modal/my-qrcode-patch-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadImagesModalComponent } from './model-popup/upload-images-modal/upload-images-modal.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ImageViewerComponent } from './model-popup/image-viewer/image-viewer.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';

 import { SharedModule } from './shared/module/shared.module';
import { OrderCompeleteModalComponent } from './model-popup/order-compelete-modal/order-compelete-modal.component';
import { VoucherModalComponent } from './model-popup/voucher-modal/voucher-modal.component'; 
import { NgxBarcodeModule } from 'ngx-barcode';
import { ESignatureComponent } from './model-popup/esignature/esignature.component';
import { CustomerInformationModalComponent } from './model-popup/customer-information-modal/customer-information-modal.component';
import { FileDropModule  } from 'ngx-file-drop';
export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    AppComponent, AppLayoutComponent,
    //TranslatePipe,

    /// -------------pop-up modal---------------//
    TradeInConfirmedModelComponent,
    MyQrcodePatchModalComponent,
    UploadImagesModalComponent,
    ImageViewerComponent,
    OrderCompeleteModalComponent,
    VoucherModalComponent,
    ESignatureComponent,
    CustomerInformationModalComponent,
    /// -------------pop-up modal---------------//
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    NgbModule,QRCodeModule,FormsModule,WebcamModule,TooltipModule,ReactiveFormsModule,
    ToastrModule.forRoot({
      // closeButton: true,
      // extendedTimeOut: 0,
      // timeOut: 0,
      // tapToDismiss: false,
      timeOut: 7000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AppRoutingModule, HttpModule, HttpClientModule, NgxSpinnerModule,LightboxModule,GalleryModule,
    NgxBarcodeModule,FileDropModule
  ],
  providers: [
    CommonService, LoaderService, AlwaysAuthGuard,
    SocketService,
    CookieService,
    NavigateService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [TradeInConfirmedModelComponent,MyQrcodePatchModalComponent,UploadImagesModalComponent,ImageViewerComponent,
    OrderCompeleteModalComponent, VoucherModalComponent, ESignatureComponent, CustomerInformationModalComponent]
})
export class AppModule { }

