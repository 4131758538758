import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { HttpClient } from '@angular/common/http';
import { SocketService } from 'src/app/service/socket/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/manual/i18n/translate.pipe';
import { TranslateService } from 'src/app/manual/i18n/translate.service';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

declare var appConfig;

@Component({
  selector: 'app-upload-images-modal',
  templateUrl: './upload-images-modal.component.html',
  styleUrls: ['./upload-images-modal.component.css']
})
export class UploadImagesModalComponent implements OnInit {
  @Output() emitService = new EventEmitter();
  @Output() emitResetUploadedImages = new EventEmitter();
  @Output() closeImageUploadModal = new EventEmitter();
  ConvertedImageBase64: any;
  CustomerDocument: Array<File>;
  @Input() OpenedPopUpToUpload
  public imageURL = appConfig.StoreImageUrl;
  sizeError: boolean = false;
  uploadedImages: any[] = [];
  isFileType: boolean;
  public showFileUpload = true;
  fileName: string = 'Upload Multiple ID Proof';
  filesToUpload: Array<File>;
  uploaderror: boolean = false;
  public filesizelimit = appConfig.FileSizeLimit
  public DocumentError = false;
  filecount1 = 0;
  filecount: boolean = false;
  public FileToUpload: Array<File> = [];
  @ViewChild('fileUpload') fileUpload: ElementRef;

  public fileLimit = appConfig.uploadImageLimit;
  public translatePipe: any;
  ///web cam start
  public FileTestFailed: any[] = [];

  public ReallyUploadFilenames: any[] = [];
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public cameraError: any;
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  //////web cam end
  public AddBodyClass = true;
  public EnterPrisePartnerID: any
  public SFTP: any
  public ShowImageOnPopup: boolean;
  public image_url: any;
  uploadstatus: boolean = false;
  currentTab: string = 'APP';
  public QrCodeImageUploadData;
  uploadImageLimit: any;
  public ShowWebCamTab = true;
  isImage: any;
  files: any;
  FileError: any;
  PopUpTitle: string;
  constructor(public activeModal: NgbActiveModal,
    private _service: CommonService,
    private http: HttpClient,
    private socketService: SocketService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toster: ToastrService, public sanitizer: DomSanitizer) {
    this.translatePipe = new TranslatePipe(translate);
  }
  ngOnInit() {
    debugger
    this.uploadImageLimit = appConfig.uploadImageLimit;
    this.SFTP = appConfig.SFTPURL;
    this.uploadPopUp(this.OpenedPopUpToUpload);
    let StoreType = JSON.parse(sessionStorage.getItem("StoreInfo"));
    this.socketService.connectSocket(StoreType.StoreID);
    this.socketService.createroom(StoreType.StoreID);
    this.EnterPrisePartnerID = StoreType.EnterprisePartnerID;
    if (appConfig.AllowQrCodeImageUpload) {
      // var rcodeforImgaeUpload = appConfig.QrCodeUploadUrl
      //   + '?apiurl=' + appConfig.url + appConfig.QrCodeUploadEndPoint
      //   + '&EnterprisePartnerID=' + StoreType.EnterprisePartnerID
      //   + '&StoreID=' + StoreType.StoreID
      //   + '&TempOrderID=' + this.OpenedPopUpToUpload.ID
      //   + '&BaseUrl=' + appConfig.url
      //   + '&IsContractImage=' + false
      //   +'&uploadImageLimit='+ appConfig.uploadImageLimit;
      this.fnGetEncryptedData(this.OpenedPopUpToUpload, StoreType.Refcode);
    } else {
      this.currentTab = 'Upload';
    }
    this.ShowWebCamTab = this.OpenedPopUpToUpload.ShowWebCamTab == undefined ? true : this.OpenedPopUpToUpload.ShowWebCamTab;
    if (this.OpenedPopUpToUpload.IsContractImage) {
      this.PopUpTitle = "Upload_Signed_Contract_Title";
    } else {
      this.PopUpTitle = "Upload_Images";
    }
  }

  fnGetEncryptedData(data, Refcode) {
    let reBody = {
      TempOrderID: data.ID
    }
    this.spinner.show();
    this._service.postmethod(reBody, 'api/verify-store-refcode/getEncryptedData').then(result => {
      debugger
      if (result.IsSuccess) {
        let IsContractImage = !data.IsContractImage ? false : true;
        var rcodeforImgaeUpload = appConfig.QrCodeUploadUrl
          + '?RefCode=' + Refcode
          + '&TempOrderID=' + result.Data
          + '&IsContractImage=' + IsContractImage
          + '&SRCODE=' + appConfig.SRCODE
        console.log('==========QrCodeImageUploadData==============');
        console.log(rcodeforImgaeUpload);
        this.QrCodeImageUploadData = rcodeforImgaeUpload;
      }
      else
        console.log(result.Error)
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      console.log(error);
    })
  }

  ngAfterViewInit() {
    debugger
    this.socketService.uploadImageSuccess((resp) => {
      const response = JSON.parse(resp);
      debugger
      if (response) {
        if (Number(response.TempID) == this.OpenedPopUpToUpload.ID) {
          this.uploadedImages = [];
          this.FileTestFailed = [];
          this.OpenedPopUpToUpload.UploadedFilesName = '';
          response.ImageNames.split(',').forEach(element => {
            // let imagePath = '';
            if (element !== '') {
              // if ((element.indexOf('iOS') !== -1)) {
              //   imagePath = 'iOS/' + element;
              // }
              // else {
              //   imagePath = 'Android/' + element;
              // }
              this.OpenedPopUpToUpload.UploadedFilesName = this.OpenedPopUpToUpload.UploadedFilesName + element + '/'

              this.uploadedImages.push(element);
              // this.showUploadBtn=true
              // this.items.push( new ImageItem({ src: this.imageURL+element, thumb: this.imageURL+element,arr:element })) 
            }
          });
          this.emitResetUploadedImages.emit(this.OpenedPopUpToUpload);
          sessionStorage.setItem('uploadedFileName', this.OpenedPopUpToUpload.UploadedFilesName);//to get names in edit page after re-load
          //  this.basicLightboxExample();
          //  this.withCustomGalleryConfig();
          if (response.ImageNames.split(',').length > 0) {
            this.emitService.emit(true);
          }
        }
      }
    })

  }
  // onSelectFile(event) {
  //   
  //   let me = this;
  //   this.sizeError = false
  //   if (this.FileTestFailed.length + this.uploadedImages.length + event.target.files.length <= appConfig.uploadImageLimit)
  //    {
  //     var file = event.target.files;
  //     var size =(file[0].size / 1024) / 1024;
  //     if (size > appConfig.FileSizeLimit) {
  //       this.sizeError = true
  //       this.uploaderror = false;
  //     }
  //     else {
  //       //var imagetmethodype = file[0].type.match('image/*');
  //       var filetmethodype = file[0].type;
  //       var imagetmethodype=false;
  //       if(filetmethodype==='image/gif' || filetmethodype==='image/jpeg' || filetmethodype==='application/pdf'){
  //         this.sizeError = false;
  //         imagetmethodype=true;
  //       }

  //       if (imagetmethodype) {
  //         this.isFileType = false;
  //         this.showFileUpload = false;
  //         setTimeout(() => {
  //           this.showFileUpload = true;
  //         }, 10);
  //         if (event.target.files && event.target.files[0]) {
  //           var filesAmount = event.target.files.length;
  //           for (let i = 0; i < filesAmount; i++) {

  //             if (this.fileName == "Upload Multiple ID Proof" || this.fileName == undefined) {
  //               this.fileName = event.target.files[i].name;
  //             } else {
  //               this.fileName = this.fileName + ", " + event.target.files[i].name;
  //             }

  //             var reader = new FileReader();
  //             reader.onload = (event1: any) => {
  //               console.log(event1.target.result);

  //               me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
  //               me.CustomerDocument = event.target.files;
  //               this.FileTestFailed.push(event1.target.result);
  //             }
  //             reader.readAsDataURL(event.target.files[i]);
  //           }

  //         }
  //       }
  //       else {
  //         this.isFileType = true
  //       }
  //     }
  //   }
  //   else {
  //     this.uploaderror = true;
  //   }
  // }
  // web cam start

  // onSelectFile(event) {
  //   

  //   this.DocumentError="";
  //       let me = this;
  //       this.sizeError = false
  //       this.filecount = false;
  //       var imagetmethodype=false;
  //       if(event.target.files.length >4)
  //       {
  //         this.DocumentError = "You can only upload " + appConfig.CustomerDocumentCount + " images"
  //         //this.removeItem(index);
  //       //  this.filecount = true
  //         this.uploadedImages = [];
  //         this.FileTestFailed = [];
  //         return;
  //       }else if(this.filecount1>=4)
  //       {
  //         this.DocumentError = "You can only upload " + appConfig.CustomerDocumentCount + " images"
  //         this.uploadedImages = [];
  //         this.FileTestFailed = [];
  //         this.fileName = "Upload Multiple ID Proof";
  //         this.filecount1=0;
  //         return;
  //       }
  //       if (this.FileTestFailed.length + this.uploadedImages.length < appConfig.uploadImageLimit) {
  //         var file = event.target.files;
  //         var size =(file[0].size / 1024) / 1024;
  //         if (size > appConfig.FileSizeLimit) {
  //           this.sizeError = true;
  //           this.isFileType = false;
  //         }
  //         else {
  //           var filesAmount = event.target.files.length;
  //           for (let i = 0; i < filesAmount; i++) {
  //           var filetmethodype = file[i].type;

  //           if(filetmethodype==='image/gif' || filetmethodype==='image/jpeg' || filetmethodype==='application/pdf'){
  //             imagetmethodype=true
  //             this.sizeError = false;
  //             this.isFileType = false;
  //           }
  //         }

  //           if (imagetmethodype) {
  //             this.isFileType = false;
  //             this.showFileUpload = false;
  //             setTimeout(() => {
  //               this.showFileUpload = true;
  //             }, 10);
  //             if (event.target.files && event.target.files[0]) {
  //               var filesAmount = event.target.files.length;
  //               for (let i = 0; i < filesAmount; i++) {
  //                 
  //                 this.filecount1++;
  //                 var file = event.target.files;
  //                 var size =(file[i].size / 1024) / 1024;
  //                 if (size > appConfig.FileSizeLimit) {
  //                   this.sizeError = true;
  //                   this.isFileType = false;
  //                 }
  //                 else{

  //                 if (this.fileName == "Upload Multiple identification documents" || this.fileName == undefined || this.filecount1<5) {
  //                     if(filetmethodype==='image/gif' || filetmethodype==='image/jpeg' || filetmethodype==='application/pdf')
  //                   {


  //                   this.fileName = event.target.files[i].name;
  //                   }
  //                   else{
  //                     this.isFileType = true;
  //                     this.filecount1--;
  //                     return;
  //                   }
  //                   var reader = new FileReader();
  //                   reader.onload = (event1: any) => {
  //                     console.log(event1.target.result);

  //                     me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
  //                     me.CustomerDocument = event.target.files;
  //                     this.FileTestFailed.push(event1.target.result);
  //                   }
  //                   reader.readAsDataURL(event.target.files[i]);
  //                 } 
  //                 else {
  //                         // if(size<appConfig.FileSizeLimit && this.filecount1<5)
  //                        // {
  //                        //                 this.fileName = this.fileName + ", " + event.target.files[i].name;
  //                       // }
  //                       // else{
  //                       //   this.sizeError = true;
  //                       //   this.isFileType = false;
  //                      // }
  //                     this.DocumentError = "You can only upload " + appConfig.CustomerDocumentCount + " images"
  //                      this.uploadedImages = [];
  //                         this.FileTestFailed = [];
  //                       this.fileName = "Upload Multiple ID Proof";
  //                        //this.filecount1=0;
  //                       // return;
  //               }


  //               }
  //             }

  //             }
  //           }
  //           else {
  //             this.isFileType = true;

  //           }
  //         }
  //       }
  //       else {
  //         this.uploaderror = true;
  //       }
  //     }
  onSelectFile(event) {

    debugger
    this.spinner.show();
    this.DocumentError = false;
    let me = this;
    this.sizeError = false
    this.filecount = false;
    var imagetmethodype = false;

    if (this.FileTestFailed.length + this.uploadedImages.length + event.target.files.length <= appConfig.uploadImageLimit) {
      var file = event.target.files;
      if (event.target.files.length > appConfig.uploadImageLimit) {
        this.DocumentError = true;
        //  this.filecount = true
        this.uploadedImages = [];
        this.FileTestFailed = [];
        this.ReallyUploadFilenames = [];
        this.filecount1 = 0;
        event.target.files = [];
        this.spinner.hide();
        return;

      }
      else if (this.filecount1 > appConfig.uploadImageLimit) {
        this.DocumentError = true;
        this.uploadedImages = [];
        this.FileTestFailed = [];
        this.ReallyUploadFilenames = [];
        // this.fileName = "Upload Multiple ID Proof";
        this.filecount1 = 0;
        this.spinner.hide();
        event.target.files = [];
        return;
      }

      if (this.FileTestFailed.length + this.uploadedImages.length < appConfig.uploadImageLimit) {

        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {

          // this.filecount1++;
          var filetmethodype = file[i].type;
          var file = event.target.files;
          var size = (file[i].size / 1024) / 1024;
          if (file[i].name.split('.').length > 2) {
            this.spinner.hide();
            this.isFileType = true;
            return;
          }
          if (size > appConfig.FileSizeLimit) {
            this.sizeError = true;
            this.isFileType = false;
            this.spinner.hide();
            break;
          }
          let isValidFileType = false;
          if (!this.OpenedPopUpToUpload.IsContractImage)
            isValidFileType = filetmethodype === 'image/gif' || filetmethodype === 'image/jpeg' || filetmethodype === 'image/png';
          else
            isValidFileType = filetmethodype === 'image/jpg' || filetmethodype === 'image/jpeg' ||
              filetmethodype === 'image/png' || filetmethodype === 'application/pdf';
          if (isValidFileType) {
            imagetmethodype = true
            this.sizeError = false;
            this.isFileType = false;
          }
          if (imagetmethodype) {
            this.isFileType = false;
            this.showFileUpload = false;
            setTimeout(() => {
              this.showFileUpload = true;
            }, 10);
            if (event.target.files && event.target.files[0]) {
              var filesAmount = event.target.files.length;


              //this.filecount1++;
              //this.fileName = event.target.files[i].name;
              // if (this.fileName == "Upload Multiple identification documents" || this.fileName == undefined || this.fileName=="Upload Multiple ID Proof" || this.fileName=="") 
              // {
              //   

              //     this.fileName = event.target.files[i].name;

              // }
              // else{
              if (isValidFileType) {

                //Callig Antivirus Api For Scanning Image              
                this.isFileType = false;
                this.showFileUpload = false;
                var reader = new FileReader();
                reader.onload = (event1: any) => {
                  var languageID = sessionStorage.getItem('LanguageID');
                  var reqbody = {
                    FilesToupload: event1.target.result,
                    UploadedFilesName: event.target.files[i].name,
                    EnterPrisePartnerID: this.EnterPrisePartnerID,
                    LanguageID: languageID
                  };
                  if (appConfig.IsAntiVirusApiCall == true) {
                    debugger
                    this._service.MakeAntivirusApiCall('api/SendFileForScanning', event.target.files[i], reqbody).subscribe((result: any) => {
                      debugger
                      setTimeout(() => {
                        this.spinner.hide();
                      }, 100);
                      if (result.Data[0] == "Allowed") {
                        debugger

                        me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
                        me.CustomerDocument = event.target.files;
                        this.filecount1++;
                        this.FileTestFailed.push(event1.target.result);
                      }
                      else if (result.Data[0] == "Blocked") {

                        this.toster.error(this.translatePipe.transform('Antivirus_warning') + " " + event.target.files[i].name + " " + this.translatePipe.transform('seems_to_be'), 'Error');

                      }
                      else {

                        this.toster.error(this.translatePipe.transform('Unable_to_run'), 'Error');
                      }
                    },
                      (error) => {
                        this.toster.error(this.translatePipe.transform('Unable_to_run'), 'Error');
                        this.spinner.hide();
                      }
                    );
                  }
                  else {
                    this.spinner.hide();
                    this.filecount1++;
                    me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
                    me.CustomerDocument = event.target.files;
                    this.FileTestFailed.push(event1.target.result);
                  }

                }


              }
              else {
                debugger
                this.spinner.hide();
                this.isFileType = true;
              }
              //this.fileName = this.fileName + ", " + event.target.files[i].name;                
              reader.readAsDataURL(event.target.files[i]);
              //}
              let obj = {
                name: event.target.files[i].name,
                type: event.target.files[i].type
              }
              this.ReallyUploadFilenames.push(obj);

            }
          }
          else {
            this.spinner.hide();
            this.isFileType = true;
          }
        }
      }
      else {
        this.spinner.hide();
        this.uploaderror = true;
      }
    }
    else {
      this.spinner.hide();
      //if(event.target.files.length>appConfig.uploadImageLimit){

      this.DocumentError = true;
      this.fileUpload.nativeElement.value = '';
      // this.ReallyUploadFilenames = [];
      //}
    }
  }

  public handleInitError(error: WebcamInitError): void {

    // console.error(error);
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.cameraError = {
        IsSuccess: false,
        Message: this.translatePipe.transform("Camera_access_was_not_allowed")
      };
    } else {
      if (error.message == "Requested device not found") {
        this.cameraError = {
          IsSuccess: false,
          Message: this.translatePipe.transform("Requested_device_not_found")

        };
      }
      else if (error.message == "Cannot read UserMedia from MediaDevices") {
        this.cameraError = {
          IsSuccess: false,
          Message: this.translatePipe.transform("Cannot_read_UserMedia_from_MediaDevice")

        };
      }
    }
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  // web cam start
  public triggerSnapshot(): void {
    this.trigger.next();
  }
  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImage(webcamImage: WebcamImage): void {

    if (this.FileTestFailed.length + this.uploadedImages.length + 1 > appConfig.uploadImageLimit ||
      this.FileTestFailed.length >= appConfig.uploadImageLimit) {
      this.sizeError = false;
      this.DocumentError = true;
      return;
    }
    this.sizeError = false;
    this.DocumentError = false;
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    const imageName = 'test' + '.jpeg';
    // call method that creates a blob from dataUri
    //const imageBlob = this.dataURItoBlob(this.webcamImage.imageAsBase64.toString());
    //this.FileTestFailed = new File([imageBlob], imageName, { type: 'image/jpeg' });
    let image = this.webcamImage.imageAsDataUrl.toString();
    this.FileTestFailed.push(image);
    let obj = {
      name: 'img-' + Math.random().toString(36).substring(7) + '.jpeg',
      type: 'image/jpeg'
    }
    this.ReallyUploadFilenames.push(obj);
  }
  public dataURItoBlob(dataURI, _type) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: _type });
    return blob;
  }


  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  uploadPopUp(items) {
    debugger
    this.DocumentError = false;
    this.filecount1 = 0;
    this.filecount = false;
    this.uploadedImages = [];
    if (items && items.UploadedFilesName && items.UploadedFilesName !== null) {
      var data = items.UploadedFilesName.split('/');
      data.splice(data.length - 1);
      // data.splice(0,1);
      data.forEach(element => {
        this.uploadedImages.push(element);
      });
    }

    this.OpenedPopUpToUpload = items;
  }
  // uploadPopUp(items) {
  //   
  //   this.uploadedImages = [];
  //   if (items.UploadedFilesName !== null) {
  //     var data = items.UploadedFilesName.split('/');
  //     data.splice(data.length - 1);
  //     // data.splice(0,1);
  //     data.forEach(element => {
  //       this.uploadedImages.push(element);
  //     });
  //   }

  //   this.OpenedPopUpToUpload = items;
  // }

  GetimageUrl(image) {
    if (image.indexOf('pdf') > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  GetAllimages(data) {
    let fullimages = '';
    data.splice(data.length - 1);
    data.forEach(element => {
      fullimages = fullimages + element + "/";
    });
    return fullimages;
  }

  uploadImages(SelectedItem) {

    this.filesToUpload = [];
    this.FileTestFailed.forEach((element, index) => {

      // let _type =  this.ReallyUploadFilenames[index].type;
      var imageType = element.split(':')[1].split(';')[0];
      console.log(imageType);
      let _type = imageType;
      let _item = element.split(',')[1];
      var blob = this.dataURItoBlob(_item, _type);
      var file = new File([blob], 'imageFileName.' + _type);
      this.filesToUpload.push(file);
    });
    let uploadedImagestring: string = '';
    this.uploadedImages.forEach(element => {
      uploadedImagestring += element + '/';
    });
    var reqbody = {
      TempOrderID: SelectedItem.ID,
      UploadedFilesName: uploadedImagestring,
      OrderDetailID: SelectedItem.OrderDetailID,
      EnterPrisePartnerID: SelectedItem.EnterprisePartnerID,
      StoreID: SelectedItem.StoreID,
      IsContractImage: this.OpenedPopUpToUpload.IsContractImage == undefined ? false : this.OpenedPopUpToUpload.IsContractImage
    };
    this.spinner.show();
    this._service.makeFileRequest('api/upload-inspection-images', this.filesToUpload, reqbody).subscribe((result: any) => {

      if (result.IsSuccess) {

        //this.uploadedImages
        this.spinner.hide();
        SelectedItem.UploadedFilesName =
          (SelectedItem.UploadedFilesName != null && SelectedItem.UploadedFilesName != undefined) ? SelectedItem.UploadedFilesName + this.GetAllimages(result.Data) : this.GetAllimages(result.Data);
        SelectedItem.InspectionImages = result.Data.filter(function (el) { return el; });
        this.OpenedPopUpToUpload.UploadedFilesName = SelectedItem.UploadedFilesName;
        this.emitResetUploadedImages.emit(this.OpenedPopUpToUpload);
        sessionStorage.setItem('uploadedFileName', SelectedItem.UploadedFilesName);//to get names in edit page after re-load
        // this.FileTestFailed = [];
        this.emitService.emit(true);
        let successMessage = this.OpenedPopUpToUpload.IsContractImage ? "Contract_Files_Uploaded_Successfully" : "Inspection_Images_Uploaded_Successfully";
        this.toster.success(this.translatePipe.transform(successMessage), this.translatePipe.transform("success"));
        const pop_closebtn: HTMLElement = document.getElementById('btn_inspectionClose') as HTMLElement;
        if (pop_closebtn) {
          pop_closebtn.click();
          // document.body.classList.add('popup-open');
        }
        if (this.OpenedPopUpToUpload.IsContractImage) {
          this.EmptyImageArrary();
        }
      } else {
        this.toster.error('An Error Occured', 'Error');
      }
    }, (error) => {
      this.toster.error(error.message, 'Error');
    });
  }
  // removeItem(index) {
  //   this.FileTestFailed.splice(index, 1);
  //   this.uploaderror = false;
  // }
  removeItem(index) {

    this.FileTestFailed.splice(index, 1);
    this.ReallyUploadFilenames.splice(index, 1);
    // this.uploaderror = false;
    let filesname = this.fileName.split(',');
    filesname.splice(index, 1);
    this.fileName = '';
    filesname.forEach(element => {
      this.fileName += element + ',';
    });
    if (this.FileTestFailed.length <= 4) {
      this.DocumentError = false
    }
    this.filecount1--;
    this.uploaderror = false;
  }
  removeUploadedImg(index) {
    this.uploadedImages.splice(index, 1);
    this.uploaderror = false;
  }

  // web cam end
  EmptyImageArrary() {

    this.FileTestFailed = [];
    this.ReallyUploadFilenames = [];
    this.uploaderror = false;
    this.activeModal.dismiss('Cross click');
    if (this.AddBodyClass)
      document.body.classList.add('popup-open');
    if (this.OpenedPopUpToUpload.IsContractImage)//To retain value for images other then contract
      this.closeImageUploadModal.emit(true);
  }
  closeImagePopUp() {
    this.activeModal.dismiss('Cross click');
    if (this.AddBodyClass)
      document.body.classList.add('popup-open');
    if (this.OpenedPopUpToUpload.IsContractImage)
      this.closeImageUploadModal.emit(true);
  }

  fnGetImage(fileName) {
    debugger
    if ((fileName.indexOf('iOS') !== -1)) {
      fileName = appConfig.ScanUploadImagePath + 'iOS/' + fileName;
    }
    else if (fileName.indexOf('Android') !== -1) {
      fileName = appConfig.ScanUploadImagePath + 'Android/' + fileName;
    } else {
      fileName = this.SFTP + fileName;
    }
    let reBody = {
      FilePath: fileName//this.SFTP + fileName
    }
    this.spinner.show();
    this._service.postmethod(reBody, 'api/GetUploadedFile').then(result => {
      debugger
      if (result.IsSuccess) {

        debugger
        var Base64String = result.Data;
        // base64 string
        var base64str = result.Data;
        if (base64str && base64str != '') {
          if (fileName.split('.').pop() == 'pdf') {
            // decode base64 string, remove space for IE compatibility
            var binary = atob(base64str.replace(/\s/g, ''));
            var len = binary.length;
            var buffer = new ArrayBuffer(len);
            var view = new Uint8Array(buffer);
            for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
            }
            // create the blob object with content-type "application/pdf"               
            var blob = new Blob([view], { type: "application/pdf" });
            var url = URL.createObjectURL(blob);
            this.ShowImageOnPopup = true;
            this.isImage = false;
            this.image_url = url;
          }
          else {
            this.ShowImageOnPopup = true;
            this.isImage = true;
            this.image_url = "data:image/png;base64," + result.Data;
          }
        } else {
          console.log('Base64 is empty');
        }
        this.spinner.hide();

      }
      else
        console.log(result.Error)
    }).catch(error => {
      this.spinner.hide();
      console.log(error);
    });


  }

  fnPreviewBrowseImage(Filedata) {
    debugger
    this.ShowImageOnPopup = true;
    if (Filedata.includes('application/pdf')) {
      Filedata = Filedata.replace('data:application/pdf;base64,', '')
      var binary = atob(Filedata.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      // create the blob object with content-type "application/pdf"               
      var blob = new Blob([view], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      this.isImage = false;
      this.image_url = url;
      // document.querySelector("iframe").src = url;
    } else {
      this.isImage = true;
      this.image_url = Filedata;
    }

  }
  CloseImagePreviewPopup() {
    this.ShowImageOnPopup = false;
  }
  currentTabChange(tab) {
    this.currentTab = tab;
  }
  removeUploadedImages() {
    this.uploadedImages = [];
    if (this.OpenedPopUpToUpload.IsContractImage)
      this.OpenedPopUpToUpload.UploadedFilesName = '';
    // this.emitResetUploadedImages.emit();
  }
  removeuploaded(imgurl, index) {
    debugger
    this.uploadedImages.splice(index, 1);
    this.uploaderror = false;
    let reBody = {
      TempID: this.OpenedPopUpToUpload.ID,//this.SFTP + fileName
      FileName: imgurl + '/',
      IsContractImage: this.OpenedPopUpToUpload.IsContractImage
    }
    this.spinner.show();
    this._service.postmethod(reBody, 'api/RemoveUploadedImage').then(result => {
      debugger
      if (result.Data[0].Success) {
        debugger
        this.OpenedPopUpToUpload.UploadedFilesName = '';
        this.uploadedImages.forEach(element => {
          this.OpenedPopUpToUpload.UploadedFilesName = this.OpenedPopUpToUpload.UploadedFilesName + element + '/';
        });
        this.emitResetUploadedImages.emit(this.OpenedPopUpToUpload);
        sessionStorage.setItem('uploadedFileName', this.OpenedPopUpToUpload.UploadedFilesName);//to get names in edit page after re-load
        // this.OpenedPopUpToUpload.UploadedFilesName = this.uploadedImages[0]+'/';
        this.toster.success("Removed successfully");
        this.spinner.hide();
      }
      else
        console.log(result.Error)
      this.spinner.hide();
    }).catch(error => {
      this.spinner.hide();
      console.log(error);
    })
  }
  dropped(event) {
    debugger
    this.uploaderror = false;
    this.sizeError = false;
    this.files = event.files;
    this.sizeError = false
    this.filecount = false;
    this.isFileType = false;
    this.DocumentError = false;
    this.FileError = '';
    // let me= this;
    if (event.files.length > 1) {
      this.FileError = this.translatePipe.transform('Upload_single_File');
      return;
    }
    this.spinner.show();
    for (const droppedFile of event.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          var totalfiles = 0;
          totalfiles = Number(appConfig.uploadImageLimit);

          if (this.FileTestFailed.length + this.uploadedImages.length + 1 <= totalfiles) {
            if (file.name.split('.').length > 2) {
              this.spinner.hide();
              this.isFileType = true;
              return;
            }
            var size = Math.ceil((file.size / 1024) / 1024);
            if (size > appConfig.FileSizeLimit) {
              this.spinner.hide();
              this.sizeError = true;
              return;
            }
            if (!this.sizeError) {
              let filetmethodype = file.type;
              let isValidFileType = false;
              if (!this.OpenedPopUpToUpload.IsContractImage)
                isValidFileType = filetmethodype === 'image/gif' || filetmethodype === 'image/jpeg' || filetmethodype === 'image/png';
              else
                isValidFileType = filetmethodype === 'image/jpg' || filetmethodype === 'image/jpeg' ||
                  filetmethodype === 'image/png' || filetmethodype === 'application/pdf';

              if (isValidFileType) {
                var reader = new FileReader();
                reader.onload = async (event1: any) => {
                  // this.FileTestFailed.push(event.target.result);
                  var languageID = sessionStorage.getItem('LanguageID');
                  var reqbody = {
                    FilesToupload: event1.target.result,
                    UploadedFilesName: file.name,
                    EnterPrisePartnerID: this.EnterPrisePartnerID,
                    LanguageID: languageID
                  };
                  if (appConfig.IsAntiVirusApiCall == true) {
                    debugger
                    this.spinner.show();
                    await this._service.MakeAntivirusApiCall('api/SendFileForScanning', file, reqbody).subscribe((result: any) => {
                      debugger
                      this.spinner.hide();
                      if (result.Data[0] == "Allowed") {
                        debugger

                        // me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
                        // me.CustomerDocument = event.target.files;
                        this.filecount1++;
                        this.FileTestFailed.push(event1.target.result);
                      }
                      else if (result.Data[0] == "Blocked") {

                        this.toster.error(this.translatePipe.transform('Antivirus_warning') + " " + file.name + " " + this.translatePipe.transform('seems_to_be'), 'Error');

                      }
                      else {

                        this.toster.error(this.translatePipe.transform('Unable_to_run'), 'Error');
                      }
                    },
                      (error) => {
                        this.toster.error(this.translatePipe.transform('Unable_to_run'), 'Error');
                        this.spinner.hide();
                      }
                    );
                  }
                  else {
                    this.spinner.hide();
                    this.filecount1++;
                    // me.ConvertedImageBase64 = me.ConvertedImageBase64 + reader.result + '#';
                    // me.CustomerDocument = event.target.files;
                    this.FileTestFailed.push(event1.target.result);
                  }
                }
                reader.readAsDataURL(file);
              } else {
                this.spinner.hide();
                this.isFileType = true;
              }
            }

          }
          else {
            this.spinner.hide();
            this.DocumentError = true;
          }
        });
      } else {
        this.spinner.hide();
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
  CaptureImageTranslation(value) {
    return value.replace('{X}', appConfig.uploadImageLimit);
  }
}
